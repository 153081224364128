<template>
  <svg width="55px" height="58px" viewBox="0 0 55 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 56 (101010) - https://sketch.com -->
    <title>839465C3-C6E0-4645-A3A7-D73285D6DD17</title>
    <desc>Created with sketchtool.</desc>
    <g id="Asset-Artboard-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icons/documents/passport" transform="translate(0.918028, 0.000000)">
            <g id="icon" transform="translate(0.000000, 1.000000)">
                <rect id="bg" stroke="#979797" fill="#D8D8D8" opacity="0" x="0.5" y="0.5" width="53" height="54.8028846"></rect>
                <g id="Group-2" transform="translate(0.000000, 9.000000)">
                    <rect id="bg-copy" :fill="color" transform="translate(27.000000, 19.000000) rotate(90.000000) translate(-27.000000, -19.000000) " x="8" y="-8" width="38" height="54" rx="2"></rect>
                    <path d="M12,4 C13.1428629,4.03137271 14.0952381,4.42352565 14.8571429,5.17647059 C15.6190476,5.92941553 16,6.87842565 16,8.02352941 C16,9.16863318 15.6190476,10.1176433 14.8571429,10.8705882 C14.0952381,11.6235332 13.1428629,12 12,12 C10.8571371,12 9.9047619,11.6235332 9.14285714,10.8705882 C8.38095238,10.1176433 8,9.16863318 8,8.02352941 C8,6.87842565 8.38095238,5.92941553 9.14285714,5.17647059 C9.9047619,4.42352565 10.8571371,4.03137271 12,4 Z M12.5,13 C14.9191154,13.0364585 16.9239687,13.4921831 18.5146199,14.3671875 C20.1052711,15.2421919 20.9337229,16.3359309 21,17.6484375 L21,20 L4,20 L4,17.6484375 C4.06627713,16.3359309 4.89472889,15.2421919 6.48538012,14.3671875 C8.07603135,13.4921831 10.0808846,13.0364585 12.5,13 Z" id="" fill="#FFFFFF" fill-rule="nonzero"></path>
                    <rect id="Rectangle-Copy-8" fill="#FFFFFF" x="4" y="24" width="46" height="3"></rect>
                    <rect id="Rectangle-Copy-11" fill="#FFFFFF" x="4" y="31" width="46" height="3"></rect>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
    props:{
        color: {
            type: String,
            // default: "#005D7B"
        }
    }
}
</script>

<style>

</style>